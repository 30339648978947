body, html {
  @apply text-zinc-900;
}

.body-bg {
  @apply bg-stone-200;
}

.nav-bg {
  background-color: #FAFAF9;
}

.body-bg-dark {
  @apply bg-gray-900;
}

.psc-text {
  @apply text-gray-700;
}

.psc-text-primary {
  @apply text-blue-700;
}

.psc-text-danger {
  @apply text-red-700;
}

:focus-visible {
  outline: 0;
}

.fa-beat-lg {
  --fa-beat-scale: 1.5;
}

input[type="checkbox"]:checked, input[type="checkbox"]:indeterminate, input[type="radio"]:checked {
  @apply bg-blue-900 accent-blue-900 !important;
}

::-ms-reveal {
  display: none;
}
.blur-sm {
  filter: blur(4px);
}
.blur-md {
  filter: blur(8px);
  opacity: 0.8;
}