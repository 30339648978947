body, html {
  --tw-text-opacity: 1;
  color: rgb(24 24 27 / var(--tw-text-opacity, 1));
}

.body-bg {
  --tw-bg-opacity: 1;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity, 1));
}

.nav-bg {
  background-color: #fafaf9;
}

.body-bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity, 1));
}

.psc-text {
  --tw-text-opacity: 1;
  color: rgb(55 65 81 / var(--tw-text-opacity, 1));
}

.psc-text-primary {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity, 1));
}

.psc-text-danger {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity, 1));
}

:focus-visible {
  outline: 0;
}

.fa-beat-lg {
  --fa-beat-scale: 1.5;
}

input[type="checkbox"]:checked, input[type="checkbox"]:indeterminate, input[type="radio"]:checked {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity, 1)) !important;
  accent-color: #1e3a8a !important;
}

::-ms-reveal {
  display: none;
}

.blur-sm {
  filter: blur(4px);
}

.blur-md {
  filter: blur(8px);
  opacity: .8;
}

.psc-h1 {
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 2.25rem;
}

.psc-h2 {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2rem;
}

.psc-link {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity, 1));
  font-weight: 500;
}

.psc-link:hover {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity, 1));
  text-decoration-line: underline;
}

.psc-link:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.psc-link-no-color {
  cursor: pointer;
  font-weight: 500;
}

.psc-link-no-color:hover {
  text-decoration-line: underline;
}

.psc-link-no-color:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.psc-link-disabled {
  cursor: not-allowed;
  --tw-text-opacity: 1;
  color: rgb(31 41 55 / var(--tw-text-opacity, 1));
  font-weight: 500;
}

.psc-link-disabled:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.psc-link-danger {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(153 27 27 / var(--tw-text-opacity, 1));
  font-weight: 500;
}

.psc-link-danger:hover {
  --tw-text-opacity: 1;
  color: rgb(185 28 28 / var(--tw-text-opacity, 1));
  text-decoration-line: underline;
}

.psc-link-danger:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

.psc-link-no-underline {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(30 64 175 / var(--tw-text-opacity, 1));
  font-weight: 500;
}

.psc-link-no-underline:hover {
  --tw-text-opacity: 1;
  color: rgb(29 78 216 / var(--tw-text-opacity, 1));
}

.psc-link-no-underline:focus {
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity, 1));
  --tw-ring-offset-width: 2px;
  outline: 2px solid #0000;
}

@keyframes button-ping {
  0% {
    opacity: .8;
    transform: scale(1);
  }

  50% {
    opacity: .3;
  }

  100% {
    opacity: 0;
    transform: scaleX(1.1)scaleY(1.35);
  }
}
/*# sourceMappingURL=index.cb36e7d1.css.map */
